'use strict';

var base = require('../product/base');
var miniCart = require('../components/miniCart');
var setdatalayer = require('../gtm/setdatalayer');
var modalFocus = require('../modalFocus');
/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

function getGTMData(element){
	var data = new Object();
	data.id = element.data('pid');
	data.masterid = element.data('masterid');
	data.name = element.data('name');
	data.price = element.data('price');
	data.color = element.data('color');
	data.size = element.data('size');
	data.width = element.data('width');
	data.qty = parseInt(element.data('quantity'));
	data.brand = element.data('brand');
	data.category = element.data('categoryfullpath');
	
	return data;
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
                'fade show" role="alert">' +
                '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
                '<span aria-hidden="true">&times;</span>' +
                '</button>' + data.valid.message + '</div>';

            $('.cart-error').append(errorHtml);
        } else {
            $('.cart').empty().append('<div class="row"> ' +
                '<div class="col-18 text-center"> ' +
                '<h1>' + data.resources.emptyCartMsg + '</h1> ' +
                '</div> ' +
                '</div>'
            );
            $('.number-of-items').empty().append(data.resources.numberOfItems);
            $('.minicart-quantity').empty().append(data.numItems);
            miniCart.updateAriaLabel(data.resources.numberOfItems);
            $('.minicart .popover').empty();
            $('.minicart .popover').removeClass('show');
        }

        $('.checkout-btn').addClass('disabled');
    } else {
        $('.checkout-btn').removeClass('disabled');
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {

	var minicartEstimatedTotalText = $(".minicart .text-right.sub-total").text();
	
    $('.number-of-items').empty().append(data.resources.numberOfItems);
    $('.shipping-cost').empty().append(data.totals.totalShippingCost);
    if (!$('.grand-total').hasClass('map-type-c')) {
        $('.tax-total').empty().append(data.totals.totalTax);
        $('.grand-total').empty().append(data.totals.grandTotal);
        $('.sub-total').empty().append(data.totals.subTotal);
    }
    $('.minicart-quantity').empty().append(data.numItems);
    miniCart.updateAriaLabel(data.resources.numberOfItems);

    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $('.order-discount').removeClass('hide-order-discount');
        $('.order-discount-total').empty()
            .append('- ' + data.totals.orderLevelDiscountTotal.formatted);
    } else {
        $('.order-discount').addClass('hide-order-discount');
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $('.shipping-discount').removeClass('hide-shipping-discount');
        $('.shipping-discount-total').empty().append('- ' +
            data.totals.shippingLevelDiscountTotal.formatted);
    } else {
        $('.shipping-discount').addClass('hide-shipping-discount');
    }

    if (data.totals.deliveryFee.active) {
        $('.delivery-fee').removeClass('hide-delivery-fee');
        $('.delivery-fee-label').empty().append(data.totals.deliveryFee.label);
        $('.delivery-fee-total').empty().append(data.totals.deliveryFee.formatted);
    } else {
        $('.delivery-fee').addClass('hide-delivery-fee');
    }

    data.items.forEach(function (item) {
        $('.item-' + item.UUID).empty().append(item.renderedPromotions);
        $('.item-total-' + item.UUID).empty().append(item.priceTotal.renderedPrice);
    	if( item.mapType && item.mapType === 'C' && minicartEstimatedTotalText.indexOf('$') === -1  ) {
    		$(".minicart .text-right.sub-total").empty().append(minicartEstimatedTotalText);
    	}
    });
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    var errorHtml = '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
        'fade show" role="alert">' +
        '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
        '<span aria-hidden="true">&times;</span>' +
        '</button>' + message + '</div>';

    $('.cart-error').append(errorHtml);
}

/**
 * Formats discount message with bold money values
 * @param {Object} message - Discount message
 */
function formatDiscountMessage(message) {
    var discountMessage = message.toString().split(/\$[0-9]+.[0-9]+/);
    var messageValues = message.toString().match(/\$[0-9]+.[0-9]+/g);
    return discountMessage[0] + "<strong>" + messageValues[0] +
            "</strong>" + discountMessage[1] + "<strong>" + messageValues[1] +
            "</strong>" + discountMessage[2];
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingDiscounts) {
    if(!approachingDiscounts) {
    	return
    }
    var html = '';
    $('.approaching-discounts').empty();
    if (approachingDiscounts.length > 0) {
    	html += '<div class="mb-2 mt-2">';
    	approachingDiscounts.forEach(function (item) {
    		html += '<div class="single-approaching-discount">'
    			+ formatDiscountMessage(item.discountMsg) + '</div>';
    	});
    	html += '</div>';
    }
	$('.approaching-discounts').append(html);
}
	
/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    var lineItem;
    var messages = '';

    for (var i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    $('.availability-' + lineItem.UUID).empty();

    if (lineItem.availability) {
        if (lineItem.availability.messages) {
            lineItem.availability.messages.forEach(function (message) {
                messages += '<p class="line-item-attributes">' + message + '</p>';
            });
        }

        if (lineItem.availability.inStockDate) {
            messages += '<p class="line-item-attributes line-item-instock-date">'
                + lineItem.availability.inStockDate
                + '</p>';
        }
    }

    $('.availability-' + lineItem.UUID).html(messages);
}

/**
 * Updates details of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateProductDetails(data, uuid) {
    var lineItem = data.cartModel.items.find(function (item) {
        return item.UUID === uuid;
    });

    if (lineItem.variationAttributes) {
        var colorAttr = lineItem.variationAttributes.find(function (attr) {
            return attr.attributeId === 'color';
        });

        if (colorAttr) {
            var colorSelector = '.Color-' + uuid;
            var newColor = 'Color: ' + colorAttr.displayValue;
            $(colorSelector).text(newColor);
        }

        var sizeAttr = lineItem.variationAttributes.find(function (attr) {
            return attr.attributeId === 'size';
        });

        if (sizeAttr) {
            var sizeSelector = '.Size-' + uuid;
            var newSize = 'Size: ' + sizeAttr.displayValue;
            $(sizeSelector).text(newSize);
        }

        var imageSelector = '.product-info.uuid-' + uuid + ' .item-image > img';
        $(imageSelector).attr('src', lineItem.images['hi-res'][0].url);
        $(imageSelector).attr('alt', lineItem.images['hi-res'][0].alt);
        $(imageSelector).attr('title', lineItem.images['hi-res'][0].title);
    }

    var qtySelector = '.quantity[data-uuid="' + uuid + '"]';
    $(qtySelector).val(lineItem.quantity);
    $(qtySelector).data('pid', data.newProductId);

    $('.remove-product[data-uuid="' + uuid + '"]').data('pid', data.newProductId);

    var priceSelector = '.line-item-price-' + uuid + ' .sales .value';
    $(priceSelector).text(lineItem.price.sales.formatted);
    $(priceSelector).attr('content', lineItem.price.sales.decimalPrice);

    if (lineItem.price.list) {
        var listPriceSelector = '.line-item-price-' + uuid + ' .list .value';
        $(listPriceSelector).text(lineItem.price.list.formatted);
        $(listPriceSelector).attr('content', lineItem.price.list.decimalPrice);
    }
}

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($('#editProductModal').length !== 0) {
        $('#editProductModal').remove();
    }
    var htmlString = '<!-- Modal -->'
        + '<div class="modal fade" id="editProductModal" role="dialog">'
        + '<div class="modal-dialog quick-view-dialog">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        &times;'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.product-quickview');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * replaces the content in the modal window for product variation to be edited.
 * @param {string} editProductUrl - url to be used to retrieve a new product model
 */
function fillModalElement(editProductUrl, productUrl) {
    $('.modal-body').spinner().start();
    $.ajax({
        url: editProductUrl,
        method: 'GET',
        dataType: 'html',
        success: function (html) {
            var parsedHtml = parseHtml(html);

            $('#editProductModal .modal-body').empty();
            $('#editProductModal .modal-body').html(parsedHtml.body);
            $('#editProductModal .modal-footer').html(parsedHtml.footer);
            $('#editProductModal .full-pdp-link').attr('href', productUrl);
            $('#editProductModal .size-chart').attr('href', productUrl);
            $('#editProductModal').modal('show');

            var carousels = require('../components/carousel');
            setTimeout(function(){
                base.initializeImages();
                carousels.initialize();
            }, 300);
            
            $.spinner().stop();
            setTimeout(function() {
                modalFocus.trapFocus($('.edit'));
            }, 500);
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

module.exports = function () {
    $('body').on('click touchstart', '.remove-product', function (e) {
        e.preventDefault();
        
        var $this = $(this);
        var actionUrl = $this.data('action');
        var productID = $this.data('pid');
        var productName = $this.data('name');
        var uuid = $this.data('uuid');
        var brand = $this.data('brand');
        var category = $this.data('category');
        var price = $this.data('price');
        var color = $this.data('color');
        var size = $this.data('size');
        var width = $this.data('width');
        var quantity = $this.data('quantity');

        var $deleteConfirmBtn = $('.cart-delete-confirmation-btn');
        var $productToRemoveSpan = $('.product-to-remove');

        $deleteConfirmBtn.data('pid', productID);
        $deleteConfirmBtn.data('action', actionUrl);
        $deleteConfirmBtn.data('uuid', uuid);
        $deleteConfirmBtn.data('brand', brand);
        $deleteConfirmBtn.data('category', category);
        $deleteConfirmBtn.data('price', price);
        $deleteConfirmBtn.data('color', color);
        $deleteConfirmBtn.data('size', size);
        $deleteConfirmBtn.data('width', width);
        $deleteConfirmBtn.data('name', productName);
        $deleteConfirmBtn.data('quantity', quantity);
        
        var data = getGTMData($this);
        $deleteConfirmBtn.data('gtmdata', data);
       
        $productToRemoveSpan.empty().append(productName);

        const body = $('body');
        if(e.currentTarget.classList.contains('remove-btn') && body.find('>[data-action="Cart-Show"]').length) return;
        let modal =  $('#maincontent #removeProductModal');
        const backdrop = $('.modal-backdrop');
        (!backdrop.length && modal.length) && body.append('<div class="modal-backdrop fade show"></div>');
        modal =  modal.length ? modal : $('#removeProductModal');
        modal.show();
        modal.addClass('show');
        modal.find('button').on('click', () => {
            modal.removeClass('show');
            modal.hide();
            $('.modal-backdrop').remove();
            body.removeClass('modal-open');
        });
    });

    $('.optional-promo').click(function (e) {
        e.preventDefault();
        $('.promo-code-form').toggle();
    });

    $('body').on('click', '.cart-delete-confirmation-btn', function (e) {
        e.preventDefault();

        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var urlParams = {
            pid: productID,
            uuid: uuid
        };
        
        //GTM Event Remove Product From Cart
        setdatalayer.RemoveLineItemfromCart($(this).data('gtmdata'));
        
        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                if (data.basket.items.length === 0) {
                    $('.cart').empty().append('<div class="row"> ' +
                        '<div class="col-18 text-center"> ' +
                        '<h1 class="page-title">' + data.basket.resources.emptyCartMsg + '</h1> ' +
                        '<div class="hidden-xs-down">' +
                        '<a class="continue-shopping-link" href="' + data.basket.resources.continueShoppingLink + '" title="' + data.basket.resources.continueShopping + '">' + data.basket.resources.continueShopping + '</a>' +
                        '</div> ' +                        
                        '</div> ' +
                        '</div>'
                    );
                    $('.number-of-items').empty().append(data.basket.resources.numberOfItems);
                    $('.minicart-quantity').empty().append(data.basket.numItems);
                    $('.minicart-container .container.cart').prepend('<div class="titlebar row"><a class="close-button pull-right" href="#"></a></div>');
                    $('#minicart-flyout .close-button').on('click', function (e) {
                        e.preventDefault();
                        $('#minicart-flyout').hide();
                    });
                    miniCart.updateAriaLabel(data.basket.resources.numberOfItems);
                    $('.minicart .popover').empty();
                    $('.minicart .popover').removeClass('show');
                    $('body').removeClass('modal-open no-scrollable');
                    $('html').removeClass('veiled');
                } else {
                	var $lineItem;
                    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                        for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                        	$lineItem = $('li.uuid-' + data.toBeDeletedUUIDs[i]);
                        	$lineItem.next('hr').remove();
                            $lineItem.remove();
                        }
                    }
                    $lineItem = $('li.uuid-' + uuid);
                    //need both hr removes for SHOE-594 SHOE-649
                    $lineItem.next('hr').remove();
                    $lineItem.parent().next('hr').remove();
                    $lineItem.remove();
                    if (!data.basket.hasBonusProduct) {
                        $('.bonus-product').remove();
                    }
                    $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
                    $('.minicart-mobile-quantity').empty().append(data.basket.resources.numberOfItems);
                    if(data.basket.totals && data.basket.totals.discounts && data.basket.totals.discounts.length > 0 && data.basket.totals.discounts[0] && data.basket.totals.discounts[0].lineItemText) {
                        $('.minicart-promo').empty().append(`<p class="promo-text">${data.basket.totals.discounts[0].lineItemText}</p>`);
                    } else if(data.basket.approachingDiscounts && data.basket.approachingDiscounts.length > 0 && data.basket.approachingDiscounts[0] && data.basket.approachingDiscounts[0].discountMsg){
                        $('.minicart-promo').empty().append(`<p class="promo-text">${formatDiscountMessage(data.basket.approachingDiscounts[0].discountMsg)}</p>`);
                    }else {
                        $('.minicart-promo').empty();
                    }
                    updateCartTotals(data.basket);
                    updateApproachingDiscounts(data.basket.approachingDiscounts);
                    $('body').trigger('setShippingMethodSelection', data.basket);
                    validateBasket(data.basket);
                }
                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });
    
    $('body').on('change', '.quantity-form > .quantity', function () {
        var preSelectQty = $(this).data('pre-select-qty');
        var quantity = $(this).val();
        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var oldQty = parseInt($(this).attr('data-pre-select-qty'));
        var newQty = parseInt($(this).closest('.quantity-form').find('.custom-select option').filter(':selected').text());
        
        var data = getGTMData($(this));
        	data.oldQty = parseInt(oldQty);
        	data.newQty = parseInt(newQty);
        	
        $(this).attr('data-pre-select-qty', newQty);
       //Change Line Item Qty for Line Item Remove Btn when value is changed
        $('button[data-uuid="'+uuid+'"]').attr('data-quantity',newQty);
        if(newQty > oldQty){
        	setdatalayer.MiniCartIncreaseQty(data);
        } else {
        	setdatalayer.MiniCartDecreaseQty(data);
        }
        
        var urlParams = {
            pid: productID,
            quantity: quantity,
            uuid: uuid
        };
        url = appendToUrl(url, urlParams);

        $(this).parents('.card').spinner().start();

        $.ajax({
            url: url,
            type: 'get',
            context: this,
            dataType: 'json',
            success: function (data) {
                $('.quantity[data-uuid="' + uuid + '"]').val(quantity);
                $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                updateCartTotals(data);
                updateApproachingDiscounts(data.approachingDiscounts);
                updateAvailability(data, uuid);
                validateBasket(data);
                $(this).data('pre-select-qty', quantity);
                $('.minicart-mobile-quantity').empty().append(data.resources.numberOfItems);
                if(data.totals && data.totals.discounts && data.totals.discounts.length > 0 && data.totals.discounts[0] && data.totals.discounts[0].lineItemText) {
                    $('.minicart-promo').empty().append(`<p class="promo-text">${data.totals.discounts[0].lineItemText}</p>`);
                } else if(data.approachingDiscounts && data.approachingDiscounts.length > 0 && data.approachingDiscounts[0] && data.approachingDiscounts[0].discountMsg) {
                    $('.minicart-promo').empty().append(`<p class="promo-text">${formatDiscountMessage(data.approachingDiscounts[0].discountMsg)}</p>`);
                } else {
                    $('.minicart-promo').empty();
                }
                $.spinner().stop();
                if ($(this).parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length) {
                    location.reload();
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $(this).val(parseInt(preSelectQty, 10));
                    $.spinner().stop();
                }
            }
        });
    });

    $('.shippingMethods').change(function () {
        var url = $(this).attr('data-actionUrl');
        var urlParams = {
            methodID: $(this).find(':selected').attr('data-shipping-id')
        };
        // url = appendToUrl(url, urlParams);

        $('.totals').spinner().start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: urlParams,
            success: function (data) {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                }
                $.spinner().stop();
            },
            error: function (err) {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('.promo-code-form').submit(function (e) {
        e.preventDefault();
        $.spinner().start();
        $('.coupon-missing-error').hide();
        $('.coupon-error-message').empty();
        if (!$('.coupon-code-field').val()) {
            $('.promo-code-form .form-control').addClass('is-invalid');
            $('.coupon-missing-error').show();
            $('#couponCode').attr('aria-describedby', 'couponMissingMsg');
            $.spinner().stop();
            $('#couponCode').trigger('focus');
            return false;
        }
        var $form = $('.promo-code-form');
        $('.promo-code-form .form-control').removeClass('is-invalid');
        $('.coupon-error-message').empty();

        $.ajax({
            url: $form.attr('action'),
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                if (data.error) {
                    $('.promo-code-form .form-control').addClass('is-invalid');
                    $('#couponCode').attr('aria-describedby', 'couponErrorMsg');
                    $('.coupon-error-message').empty().append(data.errorMessage);
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    updateCartTotals(data);
                    updateApproachingDiscounts(data.approachingDiscounts);
                    validateBasket(data);
                }
                $('.coupon-code-field').val('');
                $.spinner().stop();
                $('#couponCode').trigger('focus');
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.errorMessage);
                    $.spinner().stop();
                }
            }
        });
        return false;
    });

    $('body').on('click', '.remove-coupon', function (e) {
        e.preventDefault();

        var couponCode = $(this).data('code');
        var uuid = $(this).data('uuid');
        var $deleteConfirmBtn = $('.delete-coupon-confirmation-btn');
        var $productToRemoveSpan = $('.coupon-to-remove');

        $deleteConfirmBtn.data('uuid', uuid);
        $deleteConfirmBtn.data('code', couponCode);

        $productToRemoveSpan.empty().append(couponCode);
    });

    $('body').on('click', '.delete-coupon-confirmation-btn', function (e) {
        e.preventDefault();

        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var couponCode = $(this).data('code');
        var urlParams = {
            code: couponCode,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                $('.coupon-uuid-' + uuid).remove();
                updateCartTotals(data);
                updateApproachingDiscounts(data.approachingDiscounts);
                validateBasket(data);
                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });
    $('body').on('click', '.cart-page .bonus-product-button', function () {
        $.spinner().start();
        $.ajax({
            url: $(this).data('url'),
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                base.methods.editBonusProducts(data);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });
    $('body').on('click', '.cart-page .product-edit .edit, .cart-page .bundle-edit .edit', function (e) {
        e.preventDefault();

        var editProductUrl = $(this).attr('href');
        var productUrl = $(this).attr('data-producturl')
        getModalHtmlElement();
        fillModalElement(editProductUrl, productUrl);
    });

    $('body').on('product:updateAddToCart', function (e, response) {
        // update global add to cart (single products, bundles)
        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        $('.update-cart-product-global', dialog).attr('disabled',
            !$('.global-availability', dialog).data('ready-to-order')
            || !$('.global-availability', dialog).data('available')
        );
    });

    $('body').on('product:updateAvailability', function (e, response) {
        // bundle individual products
        $('.product-availability', response.$productContainer)
            .data('ready-to-order', response.product.readyToOrder)
            .data('available', response.product.available)
            .find('.availability-msg')
            .empty()
            .html(response.message);


        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        if ($('.product-availability', dialog).length) {
            // bundle all products
            var allAvailable = $('.product-availability', dialog).toArray()
                .every(function (item) { return $(item).data('available'); });

            var allReady = $('.product-availability', dialog).toArray()
                .every(function (item) { return $(item).data('ready-to-order'); });

            $('.global-availability', dialog)
                .data('ready-to-order', allReady)
                .data('available', allAvailable);

            $('.global-availability .availability-msg', dialog).empty()
                .html(allReady ? response.message : response.resources.info_selectforstock);
        } else {
            // single product
            $('.global-availability', dialog)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);
        }
    });

    $('body').on('product:afterAttributeSelect', function (e, response) {
        if ($('.modal.show .product-quickview .bundle-items').length) {
            $('.modal.show').find(response.container).data('pid', response.data.product.id);
            $('.modal.show').find(response.container).find('.product-id').text(response.data.product.id);
        } else {
            $('.modal.show .product-quickview').data('pid', response.data.product.id);
        }
    });

    $('body').on('change', '.quantity-select', function () {
        var selectedQuantity = $(this).val();
        $('.modal.show .update-cart-url').data('selected-quantity', selectedQuantity);
    });

    $('body').on('click', '.update-cart-product-global', function (e) {
        e.preventDefault();

        var updateProductUrl = $(this).closest('.cart-and-ipay').find('.update-cart-url').val();
        var selectedQuantity = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-quantity');
        var uuid = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('uuid');

        var form = {
            uuid: uuid,
            pid: base.getPidValue($(this)),
            quantity: selectedQuantity
        };

        $(this).parents('.card').spinner().start();

        $.ajax({
            url: updateProductUrl,
            type: 'post',
            context: this,
            data: form,
            dataType: 'json',
            success: function (data) {
                $('#editProductModal').remove();
                $('.modal-backdrop').remove();
                $('body').removeClass('modal-open');

                $('.coupons-and-promos').empty().append(data.cartModel.totals.discountsHtml);
                updateCartTotals(data.cartModel);
                updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                updateAvailability(data.cartModel, uuid);
                updateProductDetails(data, uuid);

                if (data.uuidToBeDeleted) {
                    $('.uuid-' + data.uuidToBeDeleted).remove();
                }

                validateBasket(data.cartModel);

                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    base.iniatilize();
    base.selectAttribute();
    base.buttonAttribute();
    base.availability();
    base.colorAttribute();
    base.removeBonusProduct();
    base.selectBonusProduct();
    base.enableBonusProductSelection();
    base.showMoreBonusProducts();
    base.addBonusProductsToCart();
};
